<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.1467 22.6C30.5763 21.5857 30.7479 20.4808 30.6461 19.3841C30.5443 18.2873 30.1724 17.2328 29.5635 16.3149C28.9547 15.397 28.1279 14.6443 27.157 14.124C26.1862 13.6037 25.1015 13.332 24 13.3333H22.32C21.7758 11.19 20.5788 9.26878 18.8947 7.83555C17.2107 6.40232 15.1228 5.52792 12.92 5.33334M6.6667 6.66668C4.58413 7.8182 2.94644 9.63305 2.01415 11.8226C1.08185 14.012 0.908499 16.4504 1.52166 18.7498C2.13483 21.0492 3.4993 23.0775 5.39801 24.512C7.29672 25.9466 9.62062 26.705 12 26.6667H24C24.7731 26.6658 25.54 26.5304 26.2667 26.2667M1.33336 1.33334L30.6667 30.6667"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class CloudOffIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
