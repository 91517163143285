import Vue, { ComponentOptions } from 'vue';
import { createDecorator } from 'vue-class-component';
import type { AxiosError, AxiosResponse } from 'axios';
import type IError from '@/interfaces/IError';
import CatchRule from '@/enums/config/CatchRule';
import ErrorCode from '@/enums/generics/ErrorCode';
import HttpStatus from '@/enums/generics/HttpStatus';
import AppModule from '@/store/modules/App';
import ErrorModule from '@/store/modules/Error';
import NetworkErrorMixin from '@/mixins/NetworkError';

const Catch = (catchRule = CatchRule.DEFAULT) =>
  createDecorator((options: ComponentOptions<Vue>, key: string) => {
    const useMethod = options.methods![key];

    // Wrap the method with the try catch logic
    options.methods![key] = async function wrapperMethod(...args: any) {
      this.$logger('Catch start');
      await ErrorModule.CLEAR_ERROR();

      let returnValue: any = null;
      try {
        await AppModule.CLEAR_IS_MAINTENANCE();
        returnValue = await useMethod.apply(this, args);
      } catch (e) {
        switch (catchRule) {
          case CatchRule.IGNORE:
            this.$logger('Catch rule', catchRule);
            break;
          default: {
            const onNetworkError = new NetworkErrorMixin();
            const networkError: AxiosResponse | null = (e as AxiosError).response ?? null;
            // Convert Blob to text and parse to JSON
            if (networkError?.data instanceof Blob) {
              const text = await networkError.data.text();
              networkError.data = JSON.parse(text);
            }

            if (networkError) {
              switch (networkError.status) {
                case HttpStatus.NotModified: {
                  await onNetworkError.notModified(this);
                  break;
                }
                case HttpStatus.Unauthorized: {
                  await onNetworkError.unauthorized(this);
                  break;
                }
                case HttpStatus.Forbidden: {
                  if (networkError.data.message === ErrorCode.E_ACCESS_DENIED) {
                    await onNetworkError.forbiddenAccessDenied(this);
                    break;
                  }
                  await onNetworkError.forbidden(this, networkError.data.error);
                  break;
                }
                case HttpStatus.NotFound: {
                  if (catchRule != CatchRule.NOT_FOUND) {
                    await onNetworkError.notFound(this);
                  }
                  break;
                }
                case HttpStatus.CsrfMistmatch: {
                  await onNetworkError.csrfMistmatch(this);
                  break;
                }
                case HttpStatus.ValidationError: {
                  if (catchRule != CatchRule.IMPORT) {
                    await onNetworkError.validationError(this, networkError.data.errors);
                  }
                  await onNetworkError.fileImportError(this, networkError.data.errors);
                  break;
                }
                case HttpStatus.TooManyRequests: {
                  await onNetworkError.tooManyRequests(this, networkError.data.retry_after ?? 0);
                  break;
                }
                case HttpStatus.ServiceUnavailable: {
                  await onNetworkError.serviceUnavailable(this);
                  break;
                }
                default: {
                  await onNetworkError.default(this, networkError.data.message);
                  break;
                }
              }
              // If the error is not handled by the catch rule, set the error in the store
              const data: IError = networkError.data;
              if (data.row) {
                const rowError = this.trans('workspace.title.in_row', 'Rindā Nr. :row', { row: data.row });
                data.errors[0] = `${rowError} ${data.errors[0]}`;
              }
              await ErrorModule.SET_ERROR(data.errors);
            }
            break;
          }
        }
      }
      this.$logger('Catch end');
      return returnValue;
    };
  });

export default Catch;
