<template>
  <div class="pale w-screen min-h-screen flex flex-col justify-center items-center">
    <div class="block w-full space-y-8" style="max-width: 50%">
      <div>Test Page</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class DebugPage extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
