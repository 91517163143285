import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import AppleIcon from '@/components/icons/brands/Apple.vue';
import GooglePlayIcon from '@/components/icons/brands/GooglePlay.vue';
import WhatsAppIcon from '@/components/icons/brands/WhatsApp.vue';
import BarChartIcon from '@/components/icons/chart/Bar.vue';
import LineChartIcon from '@/components/icons/chart/Line.vue';
import PdfIcon from '@/components/icons/format/Pdf.vue';
import XlsIcon from '@/components/icons/format/Xls.vue';
import XlsxIcon from '@/components/icons/format/Xlsx.vue';
import BellIcon from '@/components/icons/Bell.vue';
import BellAlertIcon from '@/components/icons/BellAlert.vue';
import BookmarkIcon from '@/components/icons/Bookmark.vue';
import BookmarkSolidIcon from '@/components/icons/BookmarkSolid.vue';
import BriefcaseIcon from '@/components/icons/Briefcase.vue';
import BriefcaseSolidIcon from '@/components/icons/BriefcaseSolid.vue';
import CalendarIcon from '@/components/icons/Calendar.vue';
import CancelIcon from '@/components/icons/Cancel.vue';
import CheckBadgeIcon from '@/components/icons/CheckBadge.vue';
import CheckMarkIcon from '@/components/icons/CheckMark.vue';
import ChevronDownIcon from '@/components/icons/ChevronDown.vue';
import ClearIcon from '@/components/icons/Clear.vue';
import ClockIcon from '@/components/icons/Clock.vue';
import CogIcon from '@/components/icons/Cog.vue';
import CopyIcon from '@/components/icons/Copy.vue';
import CreditCardIcon from '@/components/icons/CreditCard.vue';
import DocumentIcon from '@/components/icons/Document.vue';
import DotIcon from '@/components/icons/Dot.vue';
import DownloadIcon from '@/components/icons/Download.vue';
import ElipsisHorizontalIcon from '@/components/icons/ElipsisHorizontal.vue';
import EnvelopeIcon from '@/components/icons/Envelope.vue';
import EnvelopeSolidIcon from '@/components/icons/EnvelopeSolid.vue';
import EyeIcon from '@/components/icons/Eye.vue';
import EyeOffIcon from '@/components/icons/EyeOff.vue';
import FloppyIcon from '@/components/icons/Floppy.vue';
import HeadphonesIcon from '@/components/icons/Headphones.vue';
import InfoIcon from '@/components/icons/Info.vue';
import LabelIcon from '@/components/icons/Label.vue';
import LogoutIcon from '@/components/icons/Logout.vue';
import MagnifyingGlassIcon from '@/components/icons/MagnifyingGlass.vue';
import MegaphoneSolidIcon from '@/components/icons/MegaphoneSolid.vue';
import MenuIcon from '@/components/icons/Menu.vue';
import MinusIcon from '@/components/icons/Minus.vue';
import NextIcon from '@/components/icons/Next.vue';
import CloudOffIcon from '@/components/icons/CloudOff.vue';
import PencilIcon from '@/components/icons/Pencil.vue';
import PlayCircleIcon from '@/components/icons/PlayCircle.vue';
import PlusIcon from '@/components/icons/Plus.vue';
import PrevIcon from '@/components/icons/Prev.vue';
import PrinterIcon from '@/components/icons/Printer.vue';
import RefreshIcon from '@/components/icons/Refresh.vue';
import RepeatIcon from '@/components/icons/Repeat.vue';
import ShareIcon from '@/components/icons/Share.vue';
import SpinnerIcon from '@/components/icons/Spinner.vue';
import ThumbUpIcon from '@/components/icons/ThumbUp.vue';
import TrashIcon from '@/components/icons/Trash.vue';
import UploadIcon from '@/components/icons/Upload.vue';
import UploadCloudIcon from '@/components/icons/UploadCloud.vue';
import UserIcon from '@/components/icons/User.vue';
import UsersIcon from '@/components/icons/Users.vue';
import WarningIcon from '@/components/icons/Warning.vue';

Vue.use(Vuetify);

const opts = {
  treeShake: true,
  defaultAssets: false,
  icons: {
    iconfont: undefined,
    values: {
      appStore: {
        component: AppleIcon,
      },
      playStore: {
        component: GooglePlayIcon,
      },
      whatsApp: {
        component: WhatsAppIcon,
      },
      barChart: {
        component: BarChartIcon,
      },
      lineChart: {
        component: LineChartIcon,
      },
      pdf: {
        component: PdfIcon,
      },
      xls: {
        component: XlsIcon,
      },
      xlsx: {
        component: XlsxIcon,
      },
      bell: {
        component: BellIcon,
      },
      bellAlert: {
        component: BellAlertIcon,
      },
      bookmark: {
        component: BookmarkIcon,
      },
      bookmarkSolid: {
        component: BookmarkSolidIcon,
      },
      briefcase: {
        component: BriefcaseIcon,
      },
      briefcaseSolid: {
        component: BriefcaseSolidIcon,
      },
      calendar: {
        component: CalendarIcon,
      },
      cancel: {
        component: CancelIcon,
      },
      checkBadge: {
        component: CheckBadgeIcon,
      },
      checkboxIndeterminate: {
        component: MinusIcon,
      },
      checkboxOn: {
        component: CheckMarkIcon,
      },
      checkboxOff: '',
      chevronDown: {
        component: ChevronDownIcon,
      },
      clock: {
        component: ClockIcon,
      },
      cog: {
        component: CogIcon,
      },
      copy: {
        component: CopyIcon,
      },
      clear: {
        component: ClearIcon,
      },
      creditCard: {
        component: CreditCardIcon,
      },
      document: {
        component: DocumentIcon,
      },
      dot: {
        component: DotIcon,
      },
      download: {
        component: DownloadIcon,
      },
      elipsisHorizontal: {
        component: ElipsisHorizontalIcon,
      },
      envelope: {
        component: EnvelopeIcon,
      },
      envelopeSolid: {
        component: EnvelopeSolidIcon,
      },
      eye: {
        component: EyeIcon,
      },
      eyeOff: {
        component: EyeOffIcon,
      },
      floppy: {
        component: FloppyIcon,
      },
      headphones: {
        component: HeadphonesIcon,
      },
      info: {
        component: InfoIcon,
      },
      label: {
        component: LabelIcon,
      },
      logout: {
        component: LogoutIcon,
      },
      magnifyingGlass: {
        component: MagnifyingGlassIcon,
      },
      megaphoneSolid: {
        component: MegaphoneSolidIcon,
      },
      menu: {
        component: MenuIcon,
      },
      next: {
        component: NextIcon,
      },
      cloudOff: {
        component: CloudOffIcon,
      },
      pencil: {
        component: PencilIcon,
      },
      playCircle: {
        component: PlayCircleIcon,
      },
      plus: {
        component: PlusIcon,
      },
      prev: {
        component: PrevIcon,
      },
      printer: {
        component: PrinterIcon,
      },
      refresh: {
        component: RefreshIcon,
      },
      repeat: {
        component: RepeatIcon,
      },
      share: {
        component: ShareIcon,
      },
      spinner: {
        component: SpinnerIcon,
      },
      thumbUp: {
        component: ThumbUpIcon,
      },
      trash: {
        component: TrashIcon,
      },
      upload: {
        component: UploadIcon,
      },
      uploadCloud: {
        component: UploadCloudIcon,
      },
      user: {
        component: UserIcon,
      },
      users: {
        component: UsersIcon,
      },
      warning: {
        component: WarningIcon,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // Application colors
        primary: '#26AB7B',
        secondary: '#CACECB',
        error: '#F44444',

        // Base colors
        black: '#2C2C2C',
        white: '#FFFFFF',
        cultured: '#F7F7F7',
        granite: '#666666',
        silver: '#CDCFD0',
        tangerine: '#F09000',

        // Input backgrounds
        carmine: '#EF3E42',
        picton: '#44B6E1',
        eerie: '#1E1E1E',
      },
    },
  },
};

export default new Vuetify(opts);
