<template>
  <div class="block w-full">
    <slot name="button" :open="open" />
    <BaseModal v-model="showModal" :loading="isLoading" use-header use-form use-esc @cancel="close">
      <template #title>
        <p class="leading-none my-0" v-text="title" />
        <p class="leading-none mt-2 text-body-2" v-text="subtitle" />
      </template>
      <template #content>
        <v-form :disabled="isLoading" @submit.prevent class="w-full">
          <validation-observer ref="form-observer" class="flex flex-col items-center w-full gap-y-4">
            <!-- Message field -->
            <TextAreaField
              v-model="form.message"
              v-trim
              :placeholder="trans('workspace.title.email_message', 'Ziņa')"
              :label="trans('workspace.support.your_message', 'Jūsu ziņa')"
              :disabled="isLoading"
              autocomplete="message"
              counter="5000"
              rules="required|max:5000"
              name="message"
              rows="4"
              rounded
              class="text-body-2 w-full"
            />
            <!-- Drag n drop file field -->
            <DragNDropField
              v-model="form.attachments"
              :label="trans('workspace.title.attachment', 'Pielikums')"
              :disabled="isLoading || form.attachments.length >= uploadLimit"
              :allowed-mime-types="allowedMimeTypes"
              :rules="attachmentRules"
              :rules-hint="rulesHint"
              :upload-limit="uploadLimit"
              :show-error-border="!form.attachments.length"
              :show-error="!form.attachments.length"
              name="attachments"
              rounded
              class="text-body-2 w-full"
            >
              <template #footer="{ files, remove, validator }">
                <div v-if="imageFormats && videoFormats" class="whitespace-pre-line" v-text="hint" />
                <v-row
                  dense
                  class="items-center justify-start w-full pa-2"
                  v-for="(file, idx) in files"
                  :key="file.name"
                >
                  <DragNDropFilePreview
                    :validator="validator"
                    :form-error="setFormError(error, idx)"
                    :loading="isLoading"
                    :file="file"
                    class="cursor-pointer"
                    @remove="remove(file)"
                  />
                </v-row>
              </template>
            </DragNDropField>
            <!-- Send message to support button -->
            <v-row dense no-gutters class="flex items-center justify-between w-full gap-4 mt-4">
              <SimpleButton rounded outlined color="granite" :disabled="isLoading" class="px-6" @click="close">
                <span class="black--text" v-text="trans('button.cancel', 'Atcelt')" />
              </SimpleButton>
              <SubmitButton
                rounded
                :loading="isLoading"
                :text="trans('button.send', 'Nosūtīt')"
                class="px-6"
                @mousedown="support"
              />
            </v-row>
          </validation-observer>
        </v-form>
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts">
  import defer from 'lodash-es/defer';
  import cloneDeep from 'lodash-es/cloneDeep';
  import { Component, Emit, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import DEFAULT_SUPPORT_FORM from '@/constants/mocks/forms/Support';
  import MimeType from '@/enums/config/MimeType';
  import type ISupportForm from '@/interfaces/forms/ISupportForm';
  import ErrorModule from '@/store/modules/Error';
  import LoadingModule from '@/store/modules/Loading';
  import SupportMixin from '@/mixins/Support';
  import BaseModal from '@/components/global/modals/Base.vue';
  import TextAreaField from '@/components/global/inputs/TextAreaField.vue';
  import DragNDropField from '@/components/global/inputs/dragndrop/DragNDrop.vue';
  import DragNDropFilePreview from '@/components/global/inputs/dragndrop/FilePreview.vue';
  import SimpleButton from '@/components/global/buttons/Simple.vue';
  import SubmitButton from '@/components/global/buttons/Submit.vue';

  @Component({
    components: {
      BaseModal,
      TextAreaField,
      DragNDropField,
      DragNDropFilePreview,
      SimpleButton,
      SubmitButton,
    },
  })
  export default class SupportModal extends Mixins(SupportMixin) {
    @Prop({ type: Boolean }) useDelay!: boolean;

    @Ref('form-observer') formObserver!: ProviderInstance;

    public form: ISupportForm = DEFAULT_SUPPORT_FORM;
    public showModal: boolean = false;

    public uploadLimit: number = 4;

    /*****         computed       *****/

    public get error(): unknown {
      return ErrorModule.getError;
    }

    public get isLoading(): boolean {
      return LoadingModule.isLoading;
    }

    public get title(): string {
      return this.trans('workspace.support.email.title', 'Ir jautājums? Sazinieties ar mums.');
    }

    public get subtitle(): string {
      return this.trans(
        'workspace.support.email.subtitle',
        'Mūsu atbalsta komanda ar prieku atbildēs uz Jūsu jautājumiem.',
      );
    }

    public get imageFormats(): MimeType[] {
      return [MimeType.JPEG, MimeType.PNG, MimeType.BPM, MimeType.WEBP];
    }

    public get videoFormats(): MimeType[] {
      return [MimeType.MP4, MimeType.MPEG, MimeType.WEBM, MimeType.AVI];
    }

    public get allowedMimeTypes(): string {
      const formats = [...(this.imageFormats || []), ...(this.videoFormats || [])];
      return formats.join(',');
    }

    public get attachmentRules(): string {
      return `mimetypes:${this.imageFormats.join(',')},${this.videoFormats.join(',')}|filesize:4096`;
    }

    public get rulesHint(): string {
      const fileCount: string = this.trans(
        'workspace.support.email.file.guide.count',
        'Iespējams pievienot līdz pat :fileCount failiem',
        { fileCount: this.uploadLimit },
      );
      const fileSize: string = this.trans(
        'workspace.support.email.file.guide.size',
        'Viena faila maksimālais izmērs :fileSize',
        { fileSize: '4MB' },
      );

      return `${fileSize}\n${fileCount}`;
    }

    public get hint(): string {
      const allImageFormats = Object.entries(MimeType)
        .filter(([_, value]) => this.imageFormats.includes(value))
        .map(([key, _]) => key);
      const lastImageFormat = allImageFormats.pop();
      const imageFormats = `${allImageFormats.join(', ')} ${this.trans(
        'generic.or',
        'Vai',
      ).toLowerCase()} ${lastImageFormat}`;

      const allVideoFormats = Object.entries(MimeType)
        .filter(([_, value]) => this.videoFormats.includes(value))
        .map(([key, _]) => key);
      const lastVideoFormat = allVideoFormats.pop();
      const videoFormats = `${allVideoFormats.join(', ')} ${this.trans(
        'generic.or',
        'Vai',
      ).toLowerCase()} ${lastVideoFormat}`;

      return this.trans(
        'workspace.title.supported_image_video_formats',
        'Numbero atbalsta šādus attēlu formātus :imageFormats un video formātus :videoFormats',
        {
          imageFormats,
          videoFormats,
        },
      );
    }

    /*****         watchers       *****/

    @Watch('error')
    private watchForError(): void {
      if (this.error && this.formObserver) {
        const attachments = this.lookForAttachmentError();
        this.formObserver.setErrors({ ...this.error, attachments } as unknown as string[]);
      }
    }

    @Watch('showModal')
    private watchForShow(): void {
      this.resetErrors();
    }

    /*****         methods        *****/

    @Emit('request')
    public request(): void {}

    @Emit('open')
    public open(): void {
      this.useDelay ? this.openWithDelay() : this.openNow();
    }

    @Emit('close')
    public close(): void {
      this.showModal = false;
      this.resetErrors();
    }

    public async support(): Promise<void> {
      this.formObserver.reset();
      if (await this.formObserver.validate()) {
        await this.requestSupport(this.form);
        // If no errors, close modal
        if (!this.error) {
          this.request();
          return this.close();
        }

        await ErrorModule.CLEAR_ERROR();
      }
      return this.scrollToFirstError(this.formObserver);
    }

    /*****         helpers        *****/

    private openNow(): void {
      this.showModal = true;
    }

    private openWithDelay(): void {
      defer(() => {
        this.openNow();
      });
    }

    public setFormError(errors: unknown, idx: number): string {
      if (errors) {
        const error: [string, string[]] | undefined = Object.entries(errors as Record<string, string[]>).find(
          ([key]) => key === `attachments.${idx}`,
        );

        if (error) {
          const errorKey: string = error[0].toString();
          const errorValue: string = error[1][0].toString();

          return errorValue.replace(errorKey, this.trans('workspace.title.attachment', 'Pielikums'));
        }
      }
      return '';
    }

    public resetErrors(): void {
      this.form = cloneDeep(DEFAULT_SUPPORT_FORM);

      if (this.formObserver) {
        this.formObserver.reset();
        this.$nextTick(() => {
          this.formObserver.reset();
        });
      }
    }

    private lookForAttachmentError(): string {
      const error: Record<string, string[]>[] | undefined = Object.entries(this.error ?? {}).find(([key]) =>
        ['attachments'].some((e: string) => key.includes(e)),
      );

      if (error) {
        const errorKey: string = error[0].toString();
        const errorValue: string = error[1][0].toString();

        const errorMessage: string = errorValue.replace(
          errorKey,
          this.trans('workspace.title.attachment', 'Pielikums'),
        );

        return errorMessage;
      }

      return '';
    }

    /*****      vue lifecycle     *****/
  }
</script>
