<template>
  <v-dialog
    id="dialog"
    v-model="showModal"
    :eager="eager"
    :persistent="!useEsc || loading"
    :retain-focus="false"
    no-click-animation
    max-width="600px"
    content-class="overflow-hidden flex flex-col"
    @click:outside="clickOutside"
  >
    <v-card :color="colorPalette.bg" :class="{ 'pa-0 pt-2 pa-lg-6': useForm }" class="block overflow-auto h-full">
      <v-card-title v-if="useHeader" class="block w-full text-h5 mb-6 px-6" :class="colorPalette.text">
        <v-btn
          v-if="useEsc"
          :color="colorPalette.color"
          :disabled="loading"
          plain
          icon
          class="float-right shape-outside-circle mt-n2 mr-n2"
          @click="cancel"
        >
          <v-icon size="32">$clear</v-icon>
        </v-btn>
        <slot name="title">
          <p v-html="sanitizeHTML(title)" class="leading-none my-0" />
        </slot>
      </v-card-title>
      <v-card-text class="text-body-1" :class="useFullContent ? 'pa-0' : 'px-6'">
        <slot name="content">
          <div v-html="sanitizeHTML(contentText)" />
        </slot>
      </v-card-text>
      <v-card-actions
        v-if="useFooter"
        class="flex flex-col flex-md-row-reverse items-center justify-between w-full gap-6 pa-6 pt-0"
      >
        <v-btn
          :color="composeConfirmColor"
          :disabled="disabled || loading"
          :loading="loading"
          :block="isMobile"
          height="46"
          depressed
          rounded
          class="px-8"
          @click="confirm"
        >
          <span v-text="composeConfirmText" />
        </v-btn>
        <v-btn
          :color="composeCancelColor"
          :disabled="disabled || loading"
          :block="isMobile"
          height="46"
          depressed
          outlined
          rounded
          class="px-8 border-2"
          @click="deny"
        >
          <span class="black--text" v-text="composeCancelText" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
  import useBreakpoints from '@/services/Breakpoints';
  import type IColorSchema from '@/interfaces/config/IColorSchema';

  @Component
  export default class BaseModal extends Vue {
    @VModel() showModal!: boolean;

    @Prop({ type: Boolean }) eager!: boolean;
    @Prop({ type: Boolean }) useHeader!: boolean;
    @Prop({ type: Boolean }) useFooter!: boolean;
    @Prop({ type: Boolean }) useForm!: boolean;
    @Prop({ type: Boolean }) useEsc!: boolean;
    @Prop({ type: Boolean }) useFullContent!: boolean;

    @Prop({ default: 'cultured' }) color!: string;

    @Prop({ default: null }) data!: unknown | null;
    @Prop({ default: null }) title!: string | null;
    @Prop({ default: null }) contentText!: string | null;
    @Prop({ default: null }) confirmText!: string | null;
    @Prop({ default: null }) confirmColor!: string | null;
    @Prop({ default: null }) cancelText!: string | null;
    @Prop({ default: null }) cancelColor!: string | null;

    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ type: Boolean }) loading!: boolean;

    /*****         computed       *****/

    public get composeConfirmText() {
      return this.confirmText ?? this.trans('button.confirm', 'Apstiprināt');
    }

    public get composeCancelText() {
      return this.cancelText ?? this.trans('button.cancel', 'Atcelt');
    }

    public get composeConfirmColor() {
      return this.confirmColor ?? 'primary';
    }

    public get composeCancelColor() {
      return this.cancelColor ?? 'silver';
    }

    public get colorPalette(): IColorSchema {
      const textColor = this.color === 'primary' ? 'white' : 'black';

      return {
        bg: this.color,
        text: `${textColor}--text`,
        color: textColor,
      };
    }

    public get isMobile(): boolean {
      return useBreakpoints().isMobile();
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('confirm')
    public confirm(): unknown | null {
      return this.data;
    }

    @Emit('deny')
    public deny(): unknown | null {
      this.cancel();
      return this.data;
    }

    @Emit('cancel')
    public cancel(): void {}

    public clickOutside(): void {
      if (this.useEsc && !this.loading) {
        this.cancel();
      }
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>

<style></style>
