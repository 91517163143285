<template>
  <v-hover v-slot="{ hover }">
    <v-card
      elevation="0"
      rounded="lg"
      :ripple="false"
      max-height="140"
      class="block w-full border-2 transition-all cursor-default"
      :class="error ? 'border-error' : hover && !loading ? 'border-primary' : 'border-white'"
    >
      <template>
        <div class="space-y-2 pa-4">
          <div class="flex items-start justify-between gap-4">
            <div class="flex items-center justify-start gap-4" style="max-width: calc(100% - 48px)">
              <img
                v-if="imageUrl"
                :src="imageUrl"
                class="inline-flex justify-center size-40 object-cover"
                style="border-radius: 6px"
              />
              <ExcelIcon v-else class="size-40" />
              <div class="block space-y-1" style="max-width: calc(100% - 60px)">
                <div class="text-body-1 truncate" v-text="file.name" />
                <div class="granite--text" v-text="`${(file.size / 1000 / 1000).toFixed(2)}MB`" />
              </div>
            </div>
            <v-btn fab plain x-small :disabled="loading" @click="remove">
              <v-icon size="24">$clear</v-icon>
            </v-btn>
          </div>

          <div v-if="error || showUpload" class="flex items-center justify-start w-full gap-4">
            <div v-if="error" class="flex items-center justify-start gap-4">
              <div class="bg-error-10 rounded-circle pa-2">
                <v-icon color="error" size="12" style="display: block">$clear</v-icon>
              </div>
              <span class="error--text truncate" v-text="error" />
            </div>
            <div v-else class="flex items-center justify-start gap-4">
              <div class="bg-primary-10 rounded-circle pa-2">
                <v-icon color="primary" size="12" style="display: block">$checkboxOn</v-icon>
              </div>
              <span v-if="progressBar < 100" v-text="trans('notify.success.upload_started', 'Notiek augšupielāde')" />
              <span v-else v-text="trans('notify.success.upload_completed', 'Augšupielāde pabeigta')" />
            </div>
          </div>

          <div v-if="!error && showUpload" class="flex items-center justify-center w-full gap-4">
            <v-progress-linear
              v-model="progressBar"
              color="primary"
              background-color="cultured"
              height="4"
              class="rounded-lg"
            />
            <span class="granite--text" v-text="`${Math.ceil(progressBar)}%`" />
          </div>
        </div>
      </template>
    </v-card>
  </v-hover>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import ExcelIcon from '@/components/icons/static/Excel.vue';

  @Component({
    components: {
      ExcelIcon,
    },
  })
  export default class DragNDropFilePreview extends Vue {
    @Prop() validator?: ProviderInstance;
    @Prop({ default: '' }) formError?: string;
    @Prop({ type: Boolean }) showUpload?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;
    @Prop() file!: File;

    public progressBar: number = 0;

    public imageUrl: string = '';
    public error: string = '';

    /*****         computed       *****/

    public get isImage(): boolean {
      return this.file.type.startsWith('image/');
    }

    public get isVideo(): boolean {
      return this.file.type.startsWith('video/');
    }

    /*****         watchers       *****/

    @Watch('formError')
    private watchFormError(newVal: string): void {
      if (newVal) {
        this.error = newVal;
      }
    }

    @Watch('showUpload')
    private watchShowUpload(showUpload: boolean): void {
      if (showUpload && !this.error) {
        this.beginUpload();
      }
    }

    /*****         methods        *****/

    @Emit('remove')
    public remove(): void {}

    private setImageUrl(): void {
      if (this.isVideo) {
        this.setVideoThumbnail(this.file).then((img) => {
          if (typeof img === 'string') {
            this.imageUrl = img;
          }
        });
      } else if (this.isImage) {
        this.imageUrl = URL.createObjectURL(this.file);
      } else {
        this.imageUrl = '';
      }
    }

    private setVideoThumbnail(file: File) {
      return new Promise((resolve, _) => {
        // create video element
        let video = document.createElement('video');
        video.src = URL.createObjectURL(file);

        // when metadata is loaded get video duration
        video.onloadedmetadata = function () {
          // seek to the start
          video.currentTime = 0;
        };

        // when video data is loaded draw on canvas and create img
        video.onseeked = function () {
          // create canvas
          let canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // draw video frame to canvas
          let ctx = canvas.getContext('2d');
          ctx!.drawImage(video, 0, 0, canvas.width, canvas.height);

          // create img from canvas data
          let img = canvas.toDataURL('image/jpeg');
          resolve(img);
        };
      });
    }

    private beginUpload(): void {
      const interval = setInterval(async () => {
        if (this.progressBar >= 100) {
          clearInterval(interval);
        }

        this.updateProgressBar();
      }, 600);
    }

    /*****         helpers        *****/

    private updateProgressBar(): void {
      if (this.progressBar > 90 && this.progressBar < 100) {
        this.progressBar += 5;
      } else if (this.progressBar > 40 && this.progressBar < 90) {
        this.progressBar += 25;
      } else if (this.progressBar < 40) {
        this.progressBar += 35;
      }
    }

    /*****      vue lifecycle     *****/

    async mounted() {
      this.setImageUrl();

      if (this.validator) {
        const { valid, errors } = await this.validator.validate([this.file]);

        if (!valid) {
          this.error = errors[0];
        }
      }
    }
  }
</script>
