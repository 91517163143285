import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({
  store,
  name: 'Loading',
  namespaced: true,
  dynamic: true,
})
class Loading extends VuexModule {
  private loading: boolean = false;
  private loadingBulk: boolean = false;
  private loadingLocale: boolean = false;
  private searching: boolean = false;
  private loadingSingleID: string = '';

  public get isLoading(): boolean {
    return this.loading;
  }

  public get isLoadingBulk(): boolean {
    return this.loadingBulk;
  }

  public get isLoadingLocale(): boolean {
    return this.loadingLocale;
  }

  public get isSearching(): boolean {
    return this.searching;
  }

  public get getLoadingSingleID(): string {
    return this.loadingSingleID;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_LOADING' })
  public async SET_LOADING(): Promise<boolean> {
    return !this.getLoadingSingleID;
  }

  @Action({ commit: 'UPDATE_LOADING_BULK' })
  public async SET_LOADING_BULK(): Promise<boolean> {
    return !this.getLoadingSingleID;
  }

  @Action({ commit: 'UPDATE_LOADING_LOCALE' })
  public async SET_LOADING_LOCALE(): Promise<boolean> {
    return true;
  }

  @Action({ commit: 'UPDATE_SEARCHING' })
  public async SET_SEARCHING(): Promise<boolean> {
    return true;
  }

  @Action({ commit: 'UPDATE_LOADING_SINGLE_ID' })
  public async SET_LOADING_SINGLE_ID(id: string): Promise<string> {
    return id;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_LOADING' })
  public async CLEAR_LOADING(): Promise<boolean> {
    return false;
  }

  @Action({ commit: 'UPDATE_LOADING_BULK' })
  public async CLEAR_LOADING_BULK(): Promise<boolean> {
    return false;
  }

  @Action({ commit: 'UPDATE_LOADING_LOCALE' })
  public async CLEAR_LOADING_LOCALE(): Promise<boolean> {
    return false;
  }

  @Action({ commit: 'UPDATE_SEARCHING' })
  public async CLEAR_SEARCHING(): Promise<boolean> {
    return false;
  }

  @Action({ commit: 'UPDATE_LOADING_SINGLE_ID' })
  public async CLEAR_LOADING_SINGLE_ID(): Promise<string> {
    return '';
  }

  /*******   Update mutation   *******/

  @Mutation
  public UPDATE_LOADING(loading: boolean): boolean {
    return (this.loading = loading);
  }

  @Mutation
  public UPDATE_LOADING_BULK(loadingBulk: boolean): boolean {
    return (this.loadingBulk = loadingBulk);
  }

  @Mutation
  public UPDATE_LOADING_LOCALE(loadingLocale: boolean): boolean {
    return (this.loadingLocale = loadingLocale);
  }

  @Mutation
  public UPDATE_SEARCHING(searching: boolean): boolean {
    return (this.searching = searching);
  }

  @Mutation
  public UPDATE_LOADING_SINGLE_ID(id: string): string {
    return (this.loadingSingleID = id);
  }
}

const LoadingModule = getModule(Loading);

export default LoadingModule;
