<template>
  <SubmitButton
    :color="color"
    :block="block"
    :rounded="rounded"
    :loading="loading"
    :disabled="disabled"
    :outlined="outlined"
    :font-size="fontSize"
    :height="height"
    :type="type"
    @click="click"
    @mousedown="mousedown"
  >
    <slot>
      <span v-text="text" />
    </slot>
  </SubmitButton>
</template>
<script lang="ts">
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import SubmitButton from '@/components/global/buttons/Submit.vue';

  @Component({
    components: {
      SubmitButton,
    },
  })
  export default class SimpleButton extends Vue {
    @Prop({ default: 'button' }) type!: HTMLButtonElement['type'];
    @Prop({ default: 'silver' }) color!: string;
    @Prop({ default: '46' }) height!: string;

    @Prop({ default: '' }) text!: string;
    @Prop({ default: 'font-weight-medium text-body-1' }) fontSize!: string;

    @Prop({ type: Boolean }) block!: boolean;
    @Prop({ type: Boolean }) rounded!: boolean;
    @Prop({ type: Boolean }) loading!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ type: Boolean }) outlined!: boolean;

    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('click')
    public click(): void {}

    @Emit('mousedown')
    public mousedown(): void {}

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
