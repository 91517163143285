import Vue, { ComponentOptions } from 'vue';
import { createDecorator } from 'vue-class-component';
import LoadingModule from '@/store/modules/Loading';

const Searching = () =>
  createDecorator((options: ComponentOptions<Vue>, key: string) => {
    const useMethod = options.methods![key];

    // Wrap the method with the try catch logic
    options.methods![key] = async function wrapperMethod(...args: any) {
      this.$logger('Searching start');

      await LoadingModule.SET_SEARCHING();
      const returnValue = await useMethod.apply(this, args);
      await LoadingModule.CLEAR_SEARCHING();

      this.$logger('Searching end');
      return returnValue;
    };
  });

export default Searching;
