<template>
  <validation-provider
    ref="file-validator"
    :rules="rules"
    v-slot="{ errors }"
    :name="label"
    :vid="name"
    tag="div"
    class="relative flex flex-col items-start gap-y-2 text-left"
  >
    <label
      v-if="showLabel && label"
      :for="name"
      class="float-left black--text text-left text-inherit font-weight-thin leading-none"
      v-text="label"
    />
    <div class="block w-full h-full">
      <BaseDragNDrop
        v-slot="{ active, hover }"
        :disabled="disabled"
        :multiple="!!multiple"
        :rounded="rounded"
        :show-error-border="!!error(errors) && showErrorBorder"
        @drop="drop"
      >
        <label
          :for="name"
          class="block w-full h-full text-center text-body-1 pa-4 transition-all"
          :class="hover ? 'black--text' : 'granite--text'"
        >
          <!-- Drop zone and file input with disabled, active and placeholder state -->
          <div key="default" class="flex flex-col items-center justify-center gap-2 pa-2">
            <v-fade-transition group hide-on-leave leave-absolute>
              <div v-if="disabled" key="disabled" class="bg-granite-10 rounded-circle pa-3">
                <v-icon color="silver" size="32">$cloudOff</v-icon>
              </div>
              <div v-else key="placeholder" class="bg-primary-10 rounded-circle pa-3">
                <v-icon color="primary" size="32">$uploadCloud</v-icon>
              </div>
            </v-fade-transition>
            <v-fade-transition group hide-on-leave leave-absolute>
              <p
                v-if="disabled"
                key="disabled"
                class="space-x-1 ma-0"
                v-text="trans('workspace.title.upload_disabled', 'Nav iespējams pievienot jaunus failus')"
              />
              <p
                v-else-if="active"
                key="active"
                class="space-x-1 ma-0"
                v-text="trans('button.drag_and_drop', 'Ievelciet failus šeit')"
              />
              <p v-else key="placeholder" class="space-x-1 ma-0">
                <span v-text="trans('button.drag_and_drop', 'Ievelciet failus šeit')" />
                <span class="lowercase" v-text="trans('generic.or', 'Vai')" />
                <span
                  class="lowercase primary--text font-weight-medium"
                  :class="{ underline: hover }"
                  v-text="trans('button.select.file', 'Izvēlieties failu')"
                />
              </p>
            </v-fade-transition>
            <div
              v-if="rulesHint"
              class="whitespace-pre-line text-body-2 space-x-1 ma-0"
              :class="{ invisible: active || disabled }"
              v-text="rulesHint"
            />
          </div>
          <input
            :id="name"
            type="file"
            :name="name"
            :disabled="disabled"
            :multiple="!!multiple"
            :accept="allowedMimeTypes"
            class="opacity-0 absolute h-full w-full inset-0"
            :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
            @change="add"
          />
        </label>
      </BaseDragNDrop>
    </div>
    <v-scroll-y-transition hide-on-leave leave-absolute>
      <div v-if="error(errors) && showError" key="error" class="text-caption error--text" v-text="error(errors)" />
    </v-scroll-y-transition>
    <slot name="footer" :files="formValue" :remove="remove" :validator="fileValidator" />
  </validation-provider>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Ref, VModel, Vue } from 'vue-property-decorator';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import BaseDragNDrop from '@/components/global/inputs/dragndrop/Base.vue';

  @Component({
    components: {
      BaseDragNDrop,
    },
  })
  export default class DragNDropField extends Vue {
    @VModel() formValue!: File[];

    @Prop({ default: 'white' }) color?: string;
    @Prop({ default: 'primary' }) loadingColor!: string;
    @Prop({ default: '' }) placeholder?: string;
    @Prop({ default: '' }) name?: string;
    @Prop({ default: '' }) label?: string;
    @Prop({ default: 1 }) uploadLimit!: number;

    @Prop({ default: true }) showLabel?: boolean;
    @Prop({ default: true }) showError?: boolean;
    @Prop({ default: true }) showErrorBorder?: boolean;

    @Prop({ default: '' }) allowedMimeTypes?: string;
    @Prop({ default: '' }) rulesHint?: string;
    @Prop({ default: '' }) rules?: string;

    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;

    @Prop({ type: Boolean }) rounded?: boolean;

    @Ref('file-validator') fileValidator!: ProviderInstance;

    /*****         computed       *****/

    public get multiple(): boolean {
      return this.uploadLimit > 1;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    public async drop(files: FileList): Promise<void> {
      const uploadables: File[] = [...files].filter((file) => !this.fileAlreadyExists(file));
      for (const file of uploadables) {
        if (this.uploadLimit && this.formValue.length >= this.uploadLimit!) {
          break;
        }
        this.formValue.push(file);
      }
      this.$nextTick(async () => await this.validateFormValue());
    }

    public async add(e: Event): Promise<void> {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        await this.drop(files);
      }

      // Reset so that selecting the same file again will still cause it to fire this change
      (e.target as HTMLInputElement).value = '';
    }

    public async remove(file: File): Promise<void> {
      this.formValue = this.formValue.filter((f) => f !== file);
      this.$nextTick(async () => await this.validateFormValue());
    }

    @Emit('error')
    public error(errors: any): string {
      if (errors.length) {
        return errors[0];
      }
      return '';
    }

    /*****         helpers        *****/

    private fileAlreadyExists(file: File): boolean {
      return this.formValue.some((f: File) => f.name === file.name && f.size === file.size);
    }

    private async validateFormValue(): Promise<void> {
      this.fileValidator.reset();
      await this.fileValidator.validate(this.formValue);
    }

    /*****      vue lifecycle     *****/
  }
</script>
