enum MimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  BPM = 'image/bmp',
  WEBP = 'image/webp',
  MP4 = 'video/mp4',
  MPEG = 'video/mpeg',
  WEBM = 'video/webm',
  AVI = 'video/x-msvideo',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  CSV = 'text/csv',
}

export default MimeType;
