<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3335 13.8332L10.0002 10.4999M10.0002 10.4999L6.66688 13.8332M10.0002 10.4999V17.9999M16.9919 15.8249C17.8047 15.3818 18.4467 14.6806 18.8168 13.8321C19.1868 12.9835 19.2637 12.0359 19.0354 11.1388C18.807 10.2417 18.2865 9.44616 17.5558 8.87778C16.8251 8.30939 15.9259 8.00052 15.0002 7.9999H13.9502C13.698 7.02427 13.2278 6.11852 12.5752 5.35073C11.9225 4.58295 11.1042 3.97311 10.182 3.56708C9.25967 3.16104 8.25734 2.96937 7.25031 3.00647C6.24328 3.04358 5.25777 3.30849 4.36786 3.78129C3.47795 4.2541 2.7068 4.92249 2.1124 5.73622C1.51799 6.54996 1.11579 7.48785 0.936028 8.4794C0.756269 9.47095 0.803632 10.4903 1.07456 11.461C1.34548 12.4316 1.83291 13.3281 2.50021 14.0832"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class UploadCloudIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
